@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  padding-top: 165px;
}

*,
::before,
::after {
  box-sizing: border-box;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
img {
  max-width: 100%;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Playfair Display', serif;
}

h1 {
  font-size: 55px;
  margin: 0;
}
h2 {
  font-size: 30px;
  margin: 0;
}
.container {
  max-width: 1340px;
  margin: 0 auto;
}
.loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 1050;
}
@media screen and (max-width: 1340px) {
  .container {
    padding: 0 20px;
  }
}
@media screen and (max-width: 600px) {
  body {
    padding-top: 220px;
  }
}

@font-face {
  font-family: 'flaticon';
  src: url('./fonts/flaticon.ttf?1962f51994962fbde861080916c85fbe')
      format('truetype'),
    url('./fonts/flaticon.woff?1962f51994962fbde861080916c85fbe') format('woff'),
    url('./fonts/flaticon.woff2?1962f51994962fbde861080916c85fbe')
      format('woff2'),
    url('./fonts/flaticon.eot?1962f51994962fbde861080916c85fbe#iefix')
      format('embedded-opentype'),
    url('./fonts/flaticon.svg?1962f51994962fbde861080916c85fbe#flaticon')
      format('svg');
}

i[class^='flaticon-']:before,
i[class*=' flaticon-']:before {
  font-family: flaticon !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-smartphone:before {
  content: '\f101';
}
.flaticon-email:before {
  content: '\f102';
}
.flaticon-facebook:before {
  content: '\f103';
}
.flaticon-instagram:before {
  content: '\f104';
}
.flaticon-home:before {
  content: '\f105';
}
.flaticon-placeholder:before {
  content: '\f106';
}
