@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap);
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  padding-top: 165px;
}

*,
::before,
::after {
  box-sizing: border-box;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
img {
  max-width: 100%;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Playfair Display', serif;
}

h1 {
  font-size: 55px;
  margin: 0;
}
h2 {
  font-size: 30px;
  margin: 0;
}
.container {
  max-width: 1340px;
  margin: 0 auto;
}
.loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 1050;
}
@media screen and (max-width: 1340px) {
  .container {
    padding: 0 20px;
  }
}
@media screen and (max-width: 600px) {
  body {
    padding-top: 220px;
  }
}

@font-face {
  font-family: 'flaticon';
  src: url(/static/media/flaticon.008db381.ttf)
      format('truetype'),
    url(/static/media/flaticon.f5a3b40d.woff) format('woff'),
    url(/static/media/flaticon.ce90e97c.woff2)
      format('woff2'),
    url(/static/media/flaticon.b7a8ff00.eot#iefix)
      format('embedded-opentype'),
    url(/static/media/flaticon.7cbd34a7.svg#flaticon)
      format('svg');
}

i[class^='flaticon-']:before,
i[class*=' flaticon-']:before {
  font-family: flaticon !important;
  font-style: normal;
  font-weight: normal !important;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-smartphone:before {
  content: '\f101';
}
.flaticon-email:before {
  content: '\f102';
}
.flaticon-facebook:before {
  content: '\f103';
}
.flaticon-instagram:before {
  content: '\f104';
}
.flaticon-home:before {
  content: '\f105';
}
.flaticon-placeholder:before {
  content: '\f106';
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

